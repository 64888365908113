import { Canvas, View } from '@react-pdf/renderer';
import React, { Fragment } from 'react';
import { PDFCard } from '../../../common/pdf/components/PDFCard';
import { PDFH1 } from '../../../common/pdf/components/PDFH1';
import { PDFH3 } from '../../../common/pdf/components/PDFH3';
import { PDFSpacer } from '../../../common/pdf/components/PDFSpacer';
import { PDFText } from '../../../common/pdf/components/PDFText';
import { PDFTextGutter } from '../../../common/pdf/components/PDFTextGutter';
import { AcademicsList } from './export.type';
import { ReferentJobStatusEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';

export function AcademicsListFormPDF(props: {
  academicsList: AcademicsList,
  isCandidate?: boolean
}) {
  const { t } = useSafeTranslation();

  if (!props.academicsList) {
    return <PDFH1>Parcours académique du candidat</PDFH1>;
  }
  return (
    <Fragment>

      <PDFH1>Parcours académique</PDFH1>
      <PDFCard
        bordered
        title={(
          <PDFTextGutter>
            <PDFText color="white" style={{ width: '10%' }}>Année</PDFText>
            <PDFText color="white" style={{ width: '20%' }}>Précision</PDFText>
            <PDFText color="white" style={{ width: '15%' }}>Statut</PDFText>
            <PDFText color="white" style={{ width: '10%' }}>Année validée</PDFText>
            <PDFText color="white" style={{ width: '45%' }}>Etablissement et ville</PDFText>
          </PDFTextGutter>
        )}
      >
        {Object.values(props.academicsList?.academics || {}).length === 0
          && (
            <Fragment>
              <PDFTextGutter>
                <View>
                  <PDFText />
                </View>
                <View>
                  <PDFText />
                </View>
                <View>
                  <PDFText />
                </View>
                <View>
                  <PDFText />
                </View>
              </PDFTextGutter>
              <PDFSpacer />
            </Fragment>
          )}
        {props.academicsList.academics && Object.values(props.academicsList?.academics)?.map?.((a) => (
          <Fragment>
            <PDFTextGutter>
              {a.year && <PDFText style={{ width: '10%' }}>{a.year}</PDFText>}
              {!a.year && <PDFText style={{ width: '10%' }} />}
              <PDFH3 style={{ width: '90%', textAlign: 'left' }}>
                {a.schoolTrack?.id}
              </PDFH3>
            </PDFTextGutter>
            <PDFTextGutter
              key={a.year}
            >
              {a.student && (
                <Fragment>
                  <PDFText style={{ width: '10%' }}>
                    {' '}
                  </PDFText>
                  <PDFText style={{ width: '20%', paddingRight: '3' }}>
                    {a.period}
                  </PDFText>
                  <PDFText style={{ width: '15%', paddingRight: '3' }}>
                    {a.programType}
                  </PDFText>
                  <PDFText style={{ width: '10%' }}>
                    {a.degreeObtained && ' Oui'}
                    {a.degreeObtained === null && ' '}
                    {a.degreeObtained === false && ' Non'}
                  </PDFText>
                  <PDFText style={{ width: '45%' }}>
                    {a.school?.name}
                    {' '}
                    {a.school?.city?.name}
                    {' '}
                    (
                    {a.school?.city?.zipCode?.replace('@@', '')}
                    )
                  </PDFText>
                </Fragment>
              )}
            </PDFTextGutter>

            {((a.s1GenAverage?.toString()) && !props.isCandidate) && (
              <PDFTextGutter>
                <PDFSpacer />
                <View style={{ width: '10%' }}><PDFText>{' '}</PDFText></View>
                <View style={{ width: '30%' }}>
                  <PDFText>
                    Semestre 1 :
                    {' '}
                    {a.s1GenAverage}
                    {' '}
                    {a.s1Rank && (
                      <Fragment>
                        (
                        {a.s1Rank}
                        )
                      </Fragment>
                    )}
                  </PDFText>
                </View>
                <View style={{ width: '30%' }}>
                  {a.s1m1Average?.toString() && (
                    <PDFText>
                      <Fragment>
                        {a.s1m1Name} : {a.s1m1Average}
                      </Fragment>

                    </PDFText>
                  )}
                </View>
                <View style={{ width: '30%' }}>
                  {a.s1m2Average?.toString() && (
                    <PDFText>
                      <Fragment>
                        {a.s1m2Name} : {a.s1m2Average}
                      </Fragment>
                    </PDFText>
                  )}
                </View>
              </PDFTextGutter>
            )}
            {((a.s2GenAverage?.toString()) && !props.isCandidate) && (
              <PDFTextGutter>
                <PDFSpacer />
                <View style={{ width: '10%' }}><PDFText>{' '}</PDFText></View>
                <View style={{ width: '30%' }}>
                  <PDFText>
                    Semestre 2 :
                    {' '}
                    {a.s2GenAverage}
                    {' '}
                    {a.s2Rank && (
                      <Fragment>
                        (
                        {a.s2Rank}
                        )
                      </Fragment>
                    )}
                  </PDFText>
                </View>
                <View style={{ width: '30%' }}>
                  {a.s2m1Average?.toString() && (
                    <PDFText>
                      <Fragment>
                        {a.s2m1Name} : {a.s2m1Average}
                      </Fragment>
                    </PDFText>
                  )}
                </View>
                <View style={{ width: '30%' }}>
                  {a.s2m2Average?.toString() && (
                    <PDFText>
                      <Fragment>
                        {a.s2m2Name} : {a.s2m2Average}
                      </Fragment>
                    </PDFText>
                  )}
                </View>
              </PDFTextGutter>
            )}

            {!!a.nostudyActivity?.name
              && (
                <PDFTextGutter>
                  <PDFSpacer />
                  <View style={{ width: '10%' }}><PDFText>{' '}</PDFText></View>
                  <View style={{ width: '90%' }}>
                    <PDFText>
                      Activité durant l'année :{' '}
                      {a.nostudyActivity?.name}
                    </PDFText>
                  </View>
                </PDFTextGutter>
              )}

            {!!a.schoolDetail
              && (
                <PDFTextGutter>
                  <PDFSpacer />
                  <View style={{ width: '10%' }}><PDFText>{' '}</PDFText></View>
                  <View style={{ width: '90%' }}>
                    <PDFText>
                      Précisions formation :{' '}
                      {a.schoolDetail}
                    </PDFText>
                  </View>
                </PDFTextGutter>
              )}

            {!!a.cancelReason
              && (
                <PDFTextGutter>
                  <PDFSpacer />
                  <View style={{ width: '10%' }}><PDFText>{' '}</PDFText></View>
                  <View style={{ width: '90%' }}>
                    <PDFText>
                      Précisions abandon formation :{' '}
                      {a.cancelReason}
                    </PDFText>
                  </View>
                </PDFTextGutter>
              )}

            {!!a.comments
              && (
                <PDFTextGutter>
                  <PDFSpacer />
                  <View style={{ width: '10%' }}><PDFText>{' '}</PDFText></View>
                  <View style={{ width: '90%' }}>
                    <PDFText>
                      Commentaire :{' '}
                      {a.comments}
                    </PDFText>
                  </View>
                </PDFTextGutter>
              )}
            <Canvas
              style={{ width: '100%', height: '5px' }}
              paint={(painter, availableWidth) => painter.moveTo(30, 0)
                .lineTo(availableWidth - 30, 0)
                .lineTo(availableWidth - 30, 2)
                .lineTo(30, 2)
                .fill('#CCCCCC')}
            />
          </Fragment>

        ))}
      </PDFCard>

      <PDFSpacer />
      <PDFH1>Fiche Poursuite d'études</PDFH1>
      {Object.values(props.academicsList?.academicReferentReviews || {}).length === 0
        && (
          <Fragment>
            <PDFTextGutter>
              <View>
                <PDFText />
              </View>
              <View>
                <PDFText />
              </View>
              <View>
                <PDFText />
              </View>
              <View>
                <PDFText />
              </View>
            </PDFTextGutter>
            <PDFSpacer />
          </Fragment>
        )}
      {props.academicsList?.academicReferentReviews && Object.values(props.academicsList?.academicReferentReviews)?.map?.((a, i) => (

        <Fragment key={`${a.appreciation?.finalReview?.name} ${i as any}`}>
          <PDFTextGutter>
            <View style={{ width: '20%', paddingRight: '3' }}>
              <PDFText>{`${(a as any).referent?.firstName} ${(a as any).referent?.lastName}`}</PDFText>
            </View>
            <View style={{ width: '10%', paddingRight: '3' }}>
              {/* Si on a un job reconnu, on affiche sa trad, sinon, on affiche la precision dans le cas AUTRE */}
              <PDFText style={{ margin: '3' }}>{a.referent?.jobTitle?.id === ReferentJobStatusEnum.AUTRE ? 
                                                    a.referent?.jobTitlePrecision 
                                                    : t(`referent-job-status.${a.referent?.jobTitle.id}.label.html`)}</PDFText>
            </View>
            <View style={{ width: '45%' }}>
              <PDFText style={{ margin: '3' }}>{`${a.referent?.school} ${a.referent?.city}`}</PDFText>
            </View>
            <View style={{ width: '25%' }}>
              <PDFText style={{ margin: '3' }}>{`${a.referent?.email}`}</PDFText>
            </View>
          </PDFTextGutter>
          <PDFSpacer />
          {props.isCandidate || (
            <Fragment>
              <PDFTextGutter>
                <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
                <View style={{ width: '95%' }}>
                  <PDFText>
                    Etat de la demande :{' '}
                    {a.status?.id}
                  </PDFText>
                </View>
              </PDFTextGutter>
              <PDFSpacer />
              <PDFTextGutter>
                <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
                <View style={{ width: '95%' }}>
                  <PDFText>
                    Avis PE donné :{' '}
                    {a.finalReview}
                  </PDFText>
                </View>
              </PDFTextGutter>
              <PDFSpacer />
              {a.givenBySchool && (
                <Fragment>
                  <PDFTextGutter>
                    <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
                    <View style={{ width: '95%' }}>
                      <PDFText>L'établissement délivre un avis PE</PDFText>
                    </View>
                  </PDFTextGutter>
                  <PDFSpacer />
                </Fragment>
              )}
              {!!a.comments && (
                <Fragment>
                  <PDFTextGutter>
                    <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
                    <View style={{ width: '95%' }}>
                      <PDFText>
                        Commentaire :{' '}
                        {a.comments}
                      </PDFText>
                    </View>
                  </PDFTextGutter>
                  <PDFSpacer />
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      ))}
    </Fragment>
  );
}
