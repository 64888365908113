import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import React, { MutableRefObject } from 'react';
import { JuryRowContextMenu } from './JuryRowContextMenu';
import { emailColumn, emailFilter } from '../canSeeCandidateFile/candidateFilesList/columns/EmailColumn';
import {
    juryReviewStatusColumn,
    juryReviewStatusFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/JuryReviewStatusColumn';
import { avatarColumn } from '../canSeeCandidateFile/candidateFilesList/columns/AvatarColumn';
import { shortIdColumn, shortIdFilter } from '../canSeeCandidateFile/candidateFilesList/columns/ShortIdColumn';
import { firstNameColumn, firstNameFilter } from '../canSeeCandidateFile/candidateFilesList/columns/FirstNameColumn';
import { lastNameColumn, lastNameFilter } from '../canSeeCandidateFile/candidateFilesList/columns/LastNameColumn';
import { specializedSecretaryReviewCommentsFilter } from '../canSeeCandidateFile/candidateFilesList/columns/CommentsBtwSecretariesColumn';
import { lastDegreeColumn, lastDegreeFilter } from '../canSeeCandidateFile/candidateFilesList/columns/LastDegreeColumn';
import { commentsBtwAllTypesOfSecretariesFilter } from '../canSeeCandidateFile/candidateFilesList/columns/CommentsBtwAllTypesOfSecretariesColumn';
import {
    lastSchoolNameColumn,
    lastSchoolNameFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/LastSchoolNameColumn';
import {
    juryReviewStatusUpdatedAtColumn,
    juryReviewStatusUpdatedAtFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/JuryReviewStatusUpdatedAtColumn';
import { getSelectedCandidateFiles } from '../canSeeCandidateFile/candidateFilesList/CandidateFilesList';

import {
    juryReviewLastPublishedStatusColumn,
    juryReviewLastPublishedStatusFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/JuryReviewLastPublishedStatusColumn';
import {
    juryReviewPublicatedAtColumn,
    juryReviewPublicatedAtFilter,
} from '../canSeeCandidateFile/candidateFilesList/columns/JuryReviewPublicatedAtColumn';

export const CandidateFilesListInitialColumns = () => [
    shortIdColumn,
    lastNameColumn,
    firstNameColumn,
    { ...emailColumn, defaultVisible: false, defaultLocked: 'start' },
    { ...juryReviewStatusColumn, defaultVisible: true },
    { ...juryReviewStatusUpdatedAtColumn, defaultVisible: true },
    { ...juryReviewLastPublishedStatusColumn, defaultVisible: true },
    { ...juryReviewPublicatedAtColumn, defaultVisible: true },

    /* */
    { ...avatarColumn, defaultVisible: false, defaultLocked: undefined },
    { ...lastDegreeColumn, defaultVisible: false, defaultLocked: undefined },
    { ...lastSchoolNameColumn, defaultVisible: false, defaultLocked: undefined },
];

export const CandidateFilesListInitialFilters = [
    commentsBtwAllTypesOfSecretariesFilter,
    specializedSecretaryReviewCommentsFilter,
    shortIdFilter,
    firstNameFilter,
    lastNameFilter,
    emailFilter,
    shortIdFilter,
    lastSchoolNameFilter,
    lastDegreeFilter,
    juryReviewStatusFilter,
    juryReviewLastPublishedStatusFilter,
    juryReviewPublicatedAtFilter,
    juryReviewStatusUpdatedAtFilter,
];

export function renderRowContextMenu(menuProps: any,
    details: {
        rowProps: TypeRowProps;
        cellProps: TypeCellProps;
        grid: any;
        computedProps: TypeComputedProps;
        computedPropsRef: MutableRefObject<TypeComputedProps>;
    },
    selectedCandidateFiles,
    gridRef) {
    return (
        <JuryRowContextMenu
            menuProps={menuProps}
            details={details}
            selectedCandidateFiles={getSelectedCandidateFiles(details.rowProps.data, selectedCandidateFiles)}
            gridRef={gridRef}
        />
    );
}
