import React, { Fragment } from "react";
import { IDocument, IAdditionalDocument, DocumentCategoryEnum } from "@fstn/ecandidaturev2_api-interfaces"
import * as Locale from "../common/locale";

// IDocument interface is not linked in any way to a IAdditionalDocument but the backend do
// This naive type predicaye guard to extend the type to have access to the linked IAdditionalDocument
// Not great but good enough and far better than just casting the type
export function isAdditionalDocument(
	document: IDocument
): document is (IDocument & { documentAdditionalDocument: IAdditionalDocument}) {
	return document.parentType === "additionalDocument" && "documentAdditionalDocument" in document
}

/**
 * Genere la traduction du nom du document a partir des infos contenues dans additionalDocument
 * @param document Le document a partir duquel extraire les infos
 * @param t L'objet de traduction (qui ne peut etre cree ici et qu'il faut donc passer en parametre)
 * @returns Le nom genere et mis en majuscule pour que le tri soit coherent quelle que soit la casse saisie
 */
export function generateAdditionalDocFilename(document: any, t: any) {
	// Dans le cas du OTHER, le nom est simplement le nom choisi par le candidat
	if (document.documentAdditionalDocument?.category?.id === DocumentCategoryEnum.OTHER) {
		return document.documentAdditionalDocument?.name?.toUpperCase();
	} else {
		// Sinon, c'est la trad correspondant a la categorie choisie pour le document additionel
		return t(`${document.parentType}_${document.documentAdditionalDocument?.category?.id}.button.html`)?.toUpperCase();
	}
}

export function LocaleDocumentButton({ document }: { document: IDocument }) {
	if (isAdditionalDocument(document)) {
		if (document.documentAdditionalDocument?.category?.id === DocumentCategoryEnum.OTHER) {
			return <Fragment>{document.documentAdditionalDocument.name}</Fragment>
		} else {
			return <Locale.Button tkey={`${document.parentType}_${document.documentAdditionalDocument?.category?.id}`}/>
		}
	} else {
		return <Locale.Button tkey={`${document.parentType}_${document.name}`} />;
	}
}
