import filter from '@inovua/reactdatagrid-community/filter';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { IProgram, IUser, UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import moment from 'moment';
import { CandidateRow, filterTypes } from './CandidateFilesList.type';

export interface DataGridFilter {
    name: string
    operator: string
    type: string
    value: any
    emptyValue?: boolean
}

function getNestCrudOperator(reactGridFilterType, reactGridOperator) {
    switch (reactGridFilterType) {
        case 'select':
        case 'string':
        case 'filterStringWithoutNotContainsAndEmpty':
            return getNestCrudOperatorForString(reactGridOperator);
        case 'number':
            return getNestCrudOperatorForNumber(reactGridOperator);
        case 'boolean':
            return getNestCrudOperatorForBoolean(reactGridOperator);
        case 'date':
            return getNestCrudOperatorForDate(reactGridOperator);
        default:
            throw new Error(`${reactGridFilterType} Not supported`);
    }
}
/**
 * Return nestJS crud operator
 * @param reactGridOperator
 */
function getNestCrudOperatorForNumber(reactGridOperator) {
    switch (reactGridOperator) {
        case 'contains':
            return CondOperator.EQUALS;
        case 'notContains':
            return CondOperator.NOT_EQUALS;
        case 'notEmpty':
            return CondOperator.NOT_NULL;
        case 'empty':
            return CondOperator.IS_NULL;
        case 'eq':
            return CondOperator.EQUALS;
        case 'neq':
            return CondOperator.NOT_EQUALS;
        case 'gt':
            return CondOperator.GREATER_THAN;
        case 'gte':
            return CondOperator.GREATER_THAN_EQUALS;
        case 'lt':
            return CondOperator.LOWER_THAN;
        case 'lte':
            return CondOperator.LOWER_THAN_EQUALS;
        case 'inrange':
            return CondOperator.BETWEEN;
        case 'notinrange':
            return CondOperator.BETWEEN;
        case undefined:
            return;
        default:
            throw new Error(`${reactGridOperator} Not supported`);
    }
}
function getNestCrudOperatorForBoolean(reactGridOperator) {
    switch (reactGridOperator) {
        case 'contains':
        case 'notEmpty':
            return CondOperator.NOT_NULL;
        case 'empty':
            return CondOperator.IS_NULL;
        case 'eq':
            return CondOperator.EQUALS;
        case 'neq':
            return CondOperator.NOT_EQUALS;
        case undefined:
            return;
        default:
            throw new Error(`${reactGridOperator} Not supported`);
    }
}
function getNestCrudOperatorForString(reactGridOperator) {
    switch (reactGridOperator) {
        case 'contains':
            return CondOperator.CONTAINS_LOW;
        case 'notContains':
            return CondOperator.EXCLUDES_LOW;
        case 'notEmpty':
            return CondOperator.NOT_NULL;
        case 'empty':
            return CondOperator.IS_NULL;
        case 'eq':
            return CondOperator.EQUALS_LOW;
        case 'neq':
            return CondOperator.NOT_EQUALS_LOW;
        case 'inlist':
            return CondOperator.IN_LOW;
        case 'notinlist':
            return CondOperator.NOT_IN_LOW;
        case 'startsWith':
            return CondOperator.STARTS_LOW;
        case 'endsWith':
            return CondOperator.ENDS_LOW;
        case 'inrange':
            return CondOperator.BETWEEN;
        case undefined:
            return;
        default:
            throw new Error(`${reactGridOperator} Not supported`);
    }
}
function getNestCrudOperatorForDate(reactGridOperator) {
    switch (reactGridOperator) {
        case 'contains':
            return CondOperator.EQUALS;
        case 'notContains':
            return CondOperator.NOT_IN;
        case 'notEmpty':
            return CondOperator.NOT_NULL;
        case 'empty':
            return CondOperator.IS_NULL;
        case 'eq':
            return CondOperator.EQUALS;
        case 'neq':
            return CondOperator.NOT_EQUALS;
        case 'inlist':
            return CondOperator.IN;
        case 'notinlist':
            return CondOperator.NOT_IN;
        case 'after':
            return CondOperator.GREATER_THAN_EQUALS;
        case 'before':
            return CondOperator.LOWER_THAN_EQUALS;
        case 'inrange':
            return CondOperator.BETWEEN;
        case undefined:
            return;
        default:
            throw new Error(`${reactGridOperator} Not supported`);
    }
}

/**
 * Return NestJS crud field name
 * @param reactGridField
 */
function getNestCrudField(reactGridField) {
    switch (reactGridField) {
        case 'candidateFileStatus':
            return 'status.id';
        case 'lastDegreeName':
            return 'academicsList.lastDegreeName.id';
        case 'lastSchoolTrackType':
            return 'academicsList.lastDegreeName.lastSchoolTrackType.id';
        case 'email':
            return 'profile.email';
        case 'firstName':
            return 'profile.firstName';
        case 'lastName':
            return 'profile.lastName';
        case 'lastSchoolName':
            return 'academicsList.lastSchoolName.name';
        case 'lastSchoolNameId':
            return 'academicsList.lastSchoolName.id';
        case 'programLines':
            return 'programsList.programLines';
        case 'specializedSecretaryReviewComment':
            return 'specializedSecretaryReviews.comments';
        case 'secretaryLockedBy':
            return 'secretaryLockedBy.firstName';
        case 'examinerReviewReview':
            return 'examinerReviews.review';
        case 'examinerReviewNotation':
            return 'examinerReviews.notation';
        case 'programs':
            return 'programsListProgramLinesProgram.id';
        case 'lastUpdatedBy':
            return 'lastUpdatedBy.firstName';
        case 'juryReviewStatus':
            return 'juryReviews.status';
        case 'juryReviewPublicatedAt':
            return 'juryReviews.publicatedAt';
        case 'juryReviewUpdatedAt':
            return 'juryReviews.updatedAt';
        case 'juryReviewLastPublishedStatus':
            return 'juryReviews.lastPublishedStatus';
        case 'juryReviewStatusUpdatedAt':
            return 'juryReviews.statusUpdatedAt';
        case 'countSchool':
            return 'programsListProgramLinesProgram.school';
        case 'paymentDate':
            return 'paymentsListPayments.serverDate';
        default: {
            return reactGridField;
        }
    }
}

function getNestCrudValue(reactGridFilterType, reactGridFilterValue) {
    switch (reactGridFilterType) {
        case 'select': {
            if (Array.isArray(reactGridFilterValue)) {
                return reactGridFilterValue?.map((v) => (v.toLowerCase ? v.toLowerCase() : v));
            }
            return reactGridFilterValue?.toLowerCase ? reactGridFilterValue.toLowerCase() : reactGridFilterValue;
        }
        case 'string':
        case 'filterStringWithoutNotContainsAndEmpty':
            return reactGridFilterValue?.toLowerCase ? reactGridFilterValue.toLowerCase() : reactGridFilterValue;
        case 'number': {
            const start = reactGridFilterValue?.start;
            const end = reactGridFilterValue?.end;
            if (start || end) {
                return [start || 0, end || 9999999999999];
            }
            return reactGridFilterValue;
        }
        case 'boolean':
            return reactGridFilterValue;
        case 'date': {
            let start: string = reactGridFilterValue?.start;
            // On reformate les dates au format DD-MM-YYYY dans le format par défaut de postGres, a savoir YYYY-MM-DD
            if (start) {
                start = `${start.substring(6, 10)}-${start.substring(3, 5)}-${start.substring(0, 2)}`;
            }
            let end = reactGridFilterValue?.end;
            if (end) {
                end = `${end.substring(6, 10)}-${end.substring(3, 5)}-${end.substring(0, 2)}`;
            }

            if (start || end) {
                return [start || moment().startOf('year').format('YYYY-MM-DD'), end || moment().endOf('year').format('YYYY-MM-DD')];
            }
            return reactGridFilterValue;
        }
        default:
            throw new Error(`${reactGridFilterType} Not supported`);
    }
}
export function encodeSort(sortInfo: any, qb: RequestQueryBuilder, user: IUser & { program ?: IProgram }) {
    const field = getNestCrudField(sortInfo.name);
    return qb.sortBy({ field, order: sortInfo.dir === 1 ? 'ASC' : 'DESC' });
}

export function encodeFilters(filters: DataGridFilter[], qb: RequestQueryBuilder, user: IUser & { program ?: IProgram }, sortInfo?: any) {
    let applied = false;
    if (!Array.isArray(filters)) {
        filters = [];
    }
    /* Ruse pour pallier un bug etrange : si on tri sur un des champs des juryReview (qui doivent etre filtrées sur le program), ca dysfonctionne
       (des elements disparaissent de la liste si plusieurs avis existent pour un meme candidat)
       Donc si on constate qu'il faut trier sur un de ces 4 champs, on rajoute un filtre sur le program de l'utilisateur pour eliminer de la liste des reponses
       les juryReview non lies au program, ce qui resout le bug !
    */
    if (['juryReviewStatusUpdatedAt', 'juryReviewStatus', 'juryReviewLastPublishedStatus', 'juryReviewPublicatedAt'].includes(sortInfo?.name)) {
        qb.setFilter([{ field: 'juryReviewsProgram.id', operator: CondOperator.EQUALS, value: `${user.program.id}` }]);
    }

    for (const askFilter of filters.filter((f) => !!f)) {
        const operator = getNestCrudOperator(askFilter.type, askFilter.operator);
        let field = getNestCrudField(askFilter.name);
        let crudValue = getNestCrudValue(askFilter.type, askFilter.value);
        // Pour eviter des tests trop complexes en combinatoire ci-dessous, on met une valeur (qui ne sert a rien) comme operande
        // pour les operateurs IS_NULL et NOT_NULL, sinon, ils ne sont pas pris en compte si l'utilisateur ne met pas une valeur dans le champ (ce qui n'a pas de sens)
        if (operator === CondOperator.IS_NULL || operator === CondOperator.NOT_NULL) crudValue="-";  

        // remove start: ''
        if (crudValue && !crudValue.start) {
            delete crudValue.start;
        }
        if (crudValue && !crudValue.end) {
            delete crudValue.end;
        }
        if (field
            && operator
            && crudValue !== undefined
            && crudValue !== null
            && (JSON.stringify(crudValue) !== '{}')
            && (JSON.stringify(crudValue) !== '[]')
            && crudValue !== ''
            && (!Array.isArray(crudValue) || crudValue.length > 0)
            ) {
            console.log('filters', askFilter.type, operator, field, crudValue);
            /* Petite ruse de contournement pour pallier le probleme de nestjsx/crud :
                si la chaine contient une "," elle n'est pas échappée (et je ne sais pas le faire, un \, ne marche pas) et c'est du coup considéré
                par la librairie comme une liste de valeurs (et non pas une valeur contenant une ","). Du coup, la recherche échoue
               Je remplace donc la "," par @@@@ (un marqueur) qui sera remplacé à nouveau par une "," à l'issue de traitement complet côté back par cette même librairie
               Fait uniquement pour le filtre sur lastDegreeName qui est a priori le seul cas ou on peut rencontrer des "," dans la clé
            */
            if (field === 'academicsList.lastDegreeName.id') {
                crudValue = crudValue.map((s:string) => (s.replaceAll(',', '@@@@')))
            }
            // Contournement d'un probleme avec mapping different entre affichage (name) et recherche (id) qui utilise la même entrée
            // Le name doit être utilisé pour le tri, mais pour la recherche, il faut le faire sur l'id donc on remplace le champ name par id
            if (field === 'academicsList.lastSchoolName.name') field = 'academicsList.lastSchoolName.id'
            // Si on recherche sur un des 2 attributs de l'examinerReview, il faut rajouter le filtre sur l'id de l'examiner
            // car on peut avoir plusieurs avis examinateur sur un meme dossier et du coup, la recherche peut recuperer des infos des autres avis
            if (askFilter.name === 'examinerReviewReview' || askFilter.name === 'examinerReviewNotation') {
                if (user.role?.name !== UserRoleEnum.EXAMINER) {
                    throw new Error('Examiner filter can only be use for Examiner');
                }
                qb.setFilter([{ field, operator, value: crudValue },
                    { field: 'examinerReviewsExaminer.id', operator: CondOperator.EQUALS, value: `${user.id}` }]);
 
            // Pour toutes les informations liées aux avis jury (status, statut publie, date avis et date publi), il faut penser a filtrer
            // sur le program de l'utilisateur connecté (sinon, tous les avis jury sont pris en compte et pas uniquement celui lié au program du user)
            } else if (['juryReviewStatusUpdatedAt', 'juryReviewStatus', 'juryReviewLastPublishedStatus', 'juryReviewPublicatedAt'].includes(askFilter.name)) {
                if (!user.program) {
                    throw new Error('Jury filter can only be use for SF,Jury, and Examiner');
                }
                // Dans le cas du specialized secretary, jury ou examinateur il faut filter sur les avis jury qui correspondent à la formation traitée
                qb.setFilter([{ field, operator, value: crudValue },
                    { field: 'juryReviewsProgram.id', operator: CondOperator.EQUALS, value: `${user.program.id}` }]);
            } else if (['specializedSecretaryReviewComment'].includes(askFilter.name)) {
                if (!user.program) {
                    throw new Error('Jury filter can only be use for SF,Jury, and Examiner');
                }
                // Dans le cas du specialized secretary, jury ou examinateur il faut filter le commentaire sur le review qui correspond à la formation traitée
                qb.setFilter([{ field, operator, value: crudValue },
                    { field: 'sSRSpecializedSecretaryProgram.id', operator: CondOperator.EQUALS, value: `${user.program.id}` }]);
            } else {
                qb = qb.setFilter({ field, operator, value: crudValue });
            }
            applied = true;
        }
    }
    return { qb, applied };
}

export function reactDatagridFilter(data, filterValue, columnsMap) {
    // @ts-ignore
    data = filter(data, filterValue || [], filterTypes, columnsMap) as CandidateRow[];
    /**
     * When we are in mode async for datasource, reactdatagrid doesn't care about filter fn, so we have to apply fn by ourself
     */
   /* data = data.filter((value) => {
        const res = filterValue.filter((f) => f.active !== false)
            .filter((f) => f.value !== undefined && f.value !== null)
            .every((f) => {
                const op: any = Object.values(filterTypes).flatMap((ft) => ft.operators as any).find((o) => o.name === f.operator);
                if (op?.fn) {
                    return op.fn({
 value: value[f.name], filterValue: f.value, data, column: {},
});
                }
                return true;
            });
        return res;
    }); */
    return data;
}
