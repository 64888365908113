import Input, { InputProps } from 'antd/lib/input';
import React from 'react';

import { omit } from 'lodash';
import { InputNumber, InputNumberProps } from 'antd';
import { useResponsiveInputSize } from '../../hooks/use-responsive-input-size.hook';
import { DisabledContext } from '../rights/DisabledContext';
import { useScopedSelector } from '../../hooks/use-scoped-selector';

/**
 * Responsive input
 * @param props
 * @constructor
 */
export function ResponsiveInput(props: InputProps) {
    if (props.disabled !== undefined) {
        throw new Error('Use Disabled Context instead props');
    }
    const { size } = useResponsiveInputSize();
    const disabled = useScopedSelector('ResponsiveInput', DisabledContext, (c) => c?.disabled);

    return (
      <Input
        {...omit(props, 'valueMapper')}
        id={props.id}
        readOnly={disabled}
        disabled={undefined}
        size={size}
        onBlur={(e) => {
          // Meme quand le champ est "readonly", le onBlur est declenche et tente la mise a jour (rejete par le back car champ bloque en ecriture) ==> redirection page d'accueil
          // Donc on ne propage le onBlur que si le champ est autorisé en ecriture (non "disabled")
          e.stopPropagation();
          e.preventDefault();
          if (!disabled && props.onBlur) {
            props.onBlur(e);
          }
        }}
      />
    );
}

/**
 * Responsive InputNumber
 * @param props
 * @constructor
 */
export function ResponsiveInputNumber(props: InputNumberProps) {
    if (props.disabled !== undefined) {
        throw new Error('Use Disabled Context instead props');
    }
    const { size } = useResponsiveInputSize();
    const disabled = useScopedSelector('ResponsiveInput', DisabledContext, (c) => c?.disabled);

    return (
      <InputNumber
        {...omit(props, 'valueMapper')}
        id={props.id}
        readOnly={disabled}
        disabled={undefined}
        size={size}
      />
    );
}

export function ResponsiveInputPassword(props: InputProps) {
    if (props.disabled !== undefined) {
        throw new Error('Use Disabled Context instead props');
    }
    const { size } = useResponsiveInputSize();
    const disabled = useScopedSelector('ResponsiveInput', DisabledContext, (c) => c?.disabled);

    return (
      <Input.Password
        {...props}
        id={props.id}
        readOnly={disabled}
        disabled={undefined}
        size={size}
      />
    );
}
